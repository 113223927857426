.modalBody {
    color: #223232;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    max-width: 240px;
}

.modalActions {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}

.deleteButton {
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 24px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%), #F03E3D;
    background-blend-mode: overlay, normal;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
    min-width: 110px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
}

.deleteButton:hover {
    background-color: #C0392B; /* Darker red */
}

.cancelButton {
    background-color: white;
    color: #16123F;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 24px;
    border: 1px solid #16123F;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
    min-width: 110px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
}

.cancelButton:hover {
    background-color: #F2F2F2;
}
.modalBody p {
    word-wrap: break-word;
}