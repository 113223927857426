.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    align-items: center;
}

.pagination li {
    margin: 0 6px;
    cursor: pointer;
}

.pagination li a {
    color: #223232;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}

.pagination li.selected a {
    color: #223232;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
}

.pagination li.previous a,
.pagination li.next a {
    padding: 0;
    color: #1E1B4BCC;
}

.pagination li.previous.disabled a,
.pagination li.next.disabled a,
.pagination li.previous.disabled a svg path,
.pagination li.next.disabled a svg path {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-item {
    text-align: center;
}

.page-link {
    font-size: 13px;
}
