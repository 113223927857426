.wrapper {
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(5px);
}

.container {
    margin: 200px auto auto;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgba(20, 46, 110, 0.5);
    @media only screen and (max-width: 480px) {
        min-width: unset;
        width: 85%;
    }
}

.header {
    display: flex;
}

.title {
    text-align: left;
    color: #223232;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.separator {
    height: 1px;
    margin: 15px -25px;
    background: #E9EBEF;
}