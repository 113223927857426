@import "./colors.css";
@font-face {
  font-family: 'Ambit';
  font-weight: 400;
  src: local('Ambit'), url(./assets/fonts/Ambit/Ambit-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Ambit';
  font-weight: 400;
  font-style: italic;
  src: local('Ambit'), url(./assets/fonts/Ambit/Ambit-Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Ambit';
  font-weight: 700;
  src: local('Ambit'), url(./assets/fonts/Ambit/Ambit-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Ambit';
  font-weight: 600;
  src: local('Ambit'), url(./assets/fonts/Ambit/Ambit-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Ambit';
  font-weight: 600;
  font-style: italic;
  src: local('Ambit'), url(./assets/fonts/Ambit/Ambit-SemiBoldItalic.otf) format('opentype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.ck-editor__editable_inline {
  min-height: 300px;
  max-height: 600px;
  overflow: auto;
}
.ck-short-editor .ck-editor__editable_inline {
  min-height: 50px;
}
.ck.ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ck .ck-button span,
.ck-editor-view h1,
.ck-editor__editable_inline h1 {
  color: #16123f;
  font-family: 'Hepta Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 42px;
}
.ck .ck-button span,
.ck-editor-view h2,
.ck-editor__editable_inline h2 {
  color: #16123f;
  font-family: 'Hepta Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
}
.ck .ck-button span,
.ck-editor-view h3,
.ck-editor__editable_inline h3 {
  color: #16123f;
  font-family: 'Hepta Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
}

.ck-editor-view p,
.ck-editor__editable_inline p {
  font-family: 'Ambit';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.ck-editor-view ul,
.ck-editor__editable_inline ul {
  font-family: 'Ambit';
  font-style: normal;
  display: block;
  list-style-type: disc;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-block-start: 0.8rem;
  margin-block-end: 0.8rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 30px;
}

.ck-editor-view a,
.ck-editor__editable_inline a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.ck-editor-view ol,
.ck-editor__editable_inline ol {
  font-family: 'Ambit';
  display: block;
  list-style-type: decimal;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-block-start: 0.8rem;
  margin-block-end: 0.8rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 30px;
}


.ReactQueryDevtools * {
  color: white;
}


.loader-svg {
  animation: upDown 1s ease-in-out infinite;
}
.loader-circle, .loader-pointer, .loader-line {
  fill: none;
  stroke-width: .5px;
  stroke:  #4d6565;
}

.loader-center {
  fill: #4d6565;
}

.loader-pointer {
  -webkit-transform-origin: bottom center;
  -moz-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
  animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  -webkit-animation: spin 4s linear infinite;
}

.loader-line-clock {
  -webkit-transform-origin: bottom center;
  -moz-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
  /*animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  -webkit-animation: spin 60s linear infinite;*/
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg);-moz-transform-origin: 50% 50%; }
  to { -moz-transform: rotate(360deg);-moz-transform-origin: 50% 50%; }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg);-webkit-transform-origin: 50% 50%; }
  to { -webkit-transform: rotate(360deg);-webkit-transform-origin: 50% 50%; }
}
@keyframes spin {
  from {transform:rotate(0deg);transform-origin: 50% 50%;}
  to {transform:rotate(360deg);transform-origin: 50% 50%;}
}
