:root {
  --white: #fff;
  --black: #000;
  --soft-gray: #dadada;
  --spanish-gray: #979797;
  --deep-teal: #223232;
  --muted-gray: #929999;
  --silver-gray: #ccc;
  --pale-silver: #e9ebef;
  --blue-gray: #f9fafc;
  --neutral-gray: #808080;
  --iron-gray: #7f7f7f;
  --seashell: #f1f1f1;
  --frost-white: #fafbfd;
  --error-red: #f86060;
  --dusty-violet: #504d6f;
  --space-cadet: #1E1B4B;
  --ice-mist: #F2F6F9;
  
  
  --badge-primary-background: #E0BBF8;
  --badge-primary-text: #5D009A;
  --badge-neutral-background: #D3D3D3;
  --badge-neutral-text: #555555;
  --badge-error-background: #FDDEDE;
  --badge-error-text: #A31515;
  --badge-warning-background: #FEF3C7;
  --badge-warning-text: #92400E;
  --badge-success-background: #BBF7D0;
  --badge-success-text: #14532D;
  
  
}
