@import "../../../colors.css";

.accordion {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-bottom: 10px;
}

.handle {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    color: var(--space-cadet);
    margin-bottom: 16px;
}

.handle > h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}

.handleRight {
    display: flex;
    align-items: center;
    gap: 8px;
}

.handleRight > h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}

@media (max-width: 767px) {
    .handleRight > h3 {
        display: none;
    }
}

.content {
    overflow: hidden;
    position: relative;
}

.content > div {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
}

.content > div:not(.open) {
    transform: translateY(-100%);
}

.content > div.open {
    position: relative;
    display: block;
}
