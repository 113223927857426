@import "colors.css";
.wrapper {
  width: 100%;
}

.container {
  border: 1px dashed var(--soft-gray);
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.uploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  border: 1px solid var(--pale-silver);
  background-color: var(--blue-gray);
  border-radius: 2px;
  cursor: pointer;
  color: var(--neutral-gray);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.uploadButton:hover {
  background-color: var(--seashell);
}

.allowedFormats {
  color: var(--iron-gray);
  font-size: 12px;
}

.hiddenInput {
  display: none;
}

.label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.fileContainer {
  background-color: var(--frost-white);
  display: flex;
  height: 50px;
  padding: 16px;
  align-items: center;
  gap: 5px;
}

.fileContainer .cross,
.fileContainer .cross path {
  fill: var(--deep-teal);
  stroke-width: 2;
  width: 10px;
  height: 10px;
}

.fileContainer .cross:hover {
  cursor: pointer;
}

.file {
  flex: 1;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file a {
  font-size: 13px;
}

.file a:hover {
  text-decoration: underline;
}