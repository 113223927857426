@import "colors.css";
.wrapper {
  width: 100%;
}

.label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

.required {
  display: inline-block;
  color: var(--border-color-error);
  margin-left: 1px;
}

/* Loader styles */
.loader {
  max-width: 280px;
  width: 100%;
  margin-left: 1px;
}

.loaderLabel {
  width: 50%;
  height: 16px;
  margin-bottom: 1rem;
  background: var(--pale-silver);
}

.loaderSelect {
  height: 4.8rem;
  background: var(--pale-silver);
}

/* Handler styles */
.handler {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.6rem solid #223232;
  transition: transform 0.1s ease-in-out;
}

.dropdownOpen {
  transform: rotate(180deg);
}

/* Select styles */
.select {
  background: var(--white);
  border: solid 1px var(--soft-gray);
  padding: 10px 16px;
  padding-left: 16px;
  min-height: 48px;
  font-size: 13px;
  height: 48px;
}


.select:hover {
  border: solid 1px var(--silver-gray);
}

.select:focus {
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
}

.select:focus-within {
  border: solid 1px var(--soft-gray);
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
}

.select.invalid {
  border-color: var(--border-color-error);
}

.select.invalid:hover {
  border: solid 1px var(--border-color-error);
}

.select.invalid:focus {
  box-shadow: 0 0 0 0.1rem var(--border-color-error);
}

.select.invalid:focus-within {
  border: solid 1px var(--border-color-error);
  box-shadow: 0 0 0 0.1rem var(--border-color-error);
}

.select :global(.react-dropdown-select-dropdown-handle) {
  padding: 8px;
  border-left: 1px solid var(--spanish-gray);
}

.select :global(.react-dropdown-select-content) {
  padding-left: 10px;
  max-width: 95%;
    flex-wrap: nowrap;
  }
  
  .select :global(.react-dropdown-select-input) {
    font-size: 13px;
}