@import "colors.css";

.title {
    color: var(--deep-teal);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
}

.programName {
    padding: 16px 24px;
    border-radius: 8px;
    background: var(--ice-mist);
    width: fit-content;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

