.externalLogin {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.externalLogin:hover {
  text-decoration-line: underline;
}

.section {
  padding-bottom: 29px;
}

.description {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.documentUploader {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.button {
  margin-top: 32px;
  flex-basis: 100%;
  }
  
  .buttonContainer {}
  
  .select {
    max-width: 280px;
  }
  
  .upload {
    max-width: 340px;
  }
  
  @media (max-width: 580px) {
    .documentUploader {
      flex-direction: column;
    }
  
    .buttonContainer {
      display: flex;
    }
  
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0;
    }
  
    .upload {
      max-width: 100%;
    }
  
    .select {
      max-width: 100%;
      width: 100%;
    }
}

.documentContainer {
  overflow-x: auto;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: 280px 100px 50px 150px 100px auto;
  gap: 12px;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.delete {
  display: flex;
  align-items: center;
  gap: 6px;
}

.delete svg {
  height: 16px;
  width: 16px;
}

.delete path {
  color: rgba(0, 0, 0, 0.5);
}