@import "colors.css";
.badgeContainer {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    line-height: 14px;
}

/* Badge Types */
.badgePrimary {
    background-color: var(--badge-primary-background);
    color: var(--badge-primary-text);
}

.badgeNeutral {
    background-color: var(--badge-neutral-background);
    color: var(--badge-neutral-text);
}

.badgeError {
    background-color: var(--badge-error-background);
    color: var(--badge-error-text);
}

.badgeWarning {
    background-color: var(--badge-warning-background);
    color: var(--badge-warning-text);
}

.badgeSuccess {
    background-color: var(--badge-success-background);
    color: var(--badge-success-text);
}

/* Colors for Icons */
.badgePrimaryText path {
    color: var(--badge-primary-text);
}

.badgeNeutralText path {
    color: var(--badge-neutral-text);
}

.badgeErrorText path {
    color: var(--badge-error-text);
}

.badgeWarningText path {
    color: var(--badge-warning-text);
}

.badgeSuccessText path {
    color: var(--badge-success-text);
}

/* Sizes */
.badgeLarge {
    padding: 6px 10px;
    border-radius: 30px;
}

.badgeSmall {
    padding: 4px 8px;
    border-radius: 15px;
}

/* Icon positioning */
.badgeIconRight {
    flex-direction: row-reverse;
}

/* Text styles */
.badgeText {
    font-weight: 500;
}

.badgeTextLarge {
    font-size: 12px;
}

.badgeTextSmall {
    font-size: 10px;
}
